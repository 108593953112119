import React, { useState } from "react";

import { HiMenu, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Navbar.scss";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const [navScroll, setNavScroll] = useState(false);

  const changeBgNav = () => {
    const viewportH = window.scrollY;
    const maxViewportH = window.innerHeight;

    if (viewportH >= maxViewportH) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  window.addEventListener("scroll", changeBgNav);

  return (
    <nav className={`app__navbar ${navScroll ? "navbarSwitch" : null}`}>
      <div className="app__navbar-logo">
        <a href={`#home`}>
          <img
            src={navScroll ? images.logoPurple : images.logoWhite}
            alt="logo"
          />
        </a>
      </div>

      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "testimonials", "contact"].map(
          (item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )}
      </ul>

      <div className="app__navbar-menu">
        
        <HiMenu onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />

            <ul className="app__navbar-links">
              {[
                "home",
                "about",
                "work",
                "skills",
                "contact",
                "testimonials",
              ].map((item) => (
                <li key={item}>
                  <a
                    className="text-menu"
                    href={`#${item}`}
                    onClick={() => setToggle(false)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
