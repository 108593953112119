import React from "react";

import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Header.scss";

import { AppWrap } from "../../wrapper";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};
const Header = () => {



  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <div className="badge-cmp__text">
              <p className="p-text-header">Hi, I am</p>
              <h1 className="head-text-name">Sebastian</h1>
              <h2 className="head-text-name">Torres</h2>
              <p className="p-text-header">Fronted Developer</p>
            </div>
          </div>

          
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.logoPlanet}
          alt="profile-circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.redux, images.react, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`cicle-${index}`}>
            <img src={circle} alt="circle-bg" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

//<div id="home" className="app__header app__flex"></div>
// second parameter of AppWrap is the same of id="home"
export default AppWrap(Header, "home");
