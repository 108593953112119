import React from "react";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";
const SocialMedia = ({ hide }) => {
  return (
    <div className={`app__social ${hide}`}>
      <div>
        <a
          href="https://twitter.com/sebastianto_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsTwitter />
        </a>
      </div>

      <div>
        <a
          href="https://github.com/torresebas"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGithub />
        </a>
      </div>
      <div>
        <a
          href="https://www.linkedin.com/in/sebastiantorrest/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsLinkedin />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
